<template>
  <div>
    <eden-table-actions :title="title" @search="search">
      <template slot="actions" v-if="allowAccessFor(['admin', 'growth'])">
        <el-button type="primary" @click="showBadgeForm = true"
          >Add Badge</el-button
        >
      </template>
    </eden-table-actions>
    <eden-loader v-if="loading" />
    <template v-else>
      <el-table v-if="pageData.length" :data="pageData">
        <el-table-column prop="badge">
          <template #header>
            <eden-table-column-header
              :label="'Badge'"
              :property="'name'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'name')"
            />
          </template>
          <template v-slot="scope">
            <router-link
              :to="{
                name: 'discounts.badges.index',
                params: { id: scope.row.id },
              }"
            >
              <div class="badge">
                <div class="badge-image">
                  <img
                    v-if="scope.row.badge_image_url"
                    :src="getThumbnailFromCloudinary(scope.row.badge_image_url)"
                    :style="setBadgeStyle(scope.row.background_colour)"
                    alt="Image"
                  />
                </div>
                <div class="badge-name">
                  <p>{{ scope.row.name }}</p>
                  <span>{{ scope.row.description }}</span>
                </div>
              </div>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column width="200">
          <template #header>
            <eden-table-column-header
              :label="'Users with this badge'"
              :property="'owners_count'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'owners_count')"
            />
          </template>

          <template v-slot="scope">
            <span class="text-primary">{{ scope.row.owners_count }}</span>
          </template>
        </el-table-column>
        <el-table-column width="300">
          <template #header>
            <eden-table-column-header
              :label="'Status'"
              :property="'pending_status'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'pending_status')"
            />
          </template>
          <template v-slot="scope">
            <el-tag
              :type="setType(scope.row.pending_status ? 'pending' : 'live')"
            >
              {{
                scope.row.pending_status
                  ? "Pending implementation"
                  : "Live on Garden"
              }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column width="60">
          <template v-slot="scope">
            <badge-actions :badge="scope.row" @update="getBadges" />
          </template>
        </el-table-column>
      </el-table>
      <eden-content-empty v-else :text="'No badges'" />
    </template>
    <badge-form
      :action="'add'"
      :show.sync="showBadgeForm"
      @success="getBadges"
    />
  </div>
</template>

<script>
import BadgeForm from "@/components/DiscountsAndRewards/Badges/BadgeForm";
import BadgeActions from "@/components/DiscountsAndRewards/Badges/Actions/BadgeActions";

import badges from "@/requests/discounts/badges";

export default {
  name: "Badges",
  components: {
    BadgeForm,
    BadgeActions,
  },
  data() {
    return {
      loading: false,
      sort: {
        property: "name",
        direction: "asc",
      },
      pageData: [],
      showBadgeForm: false,
    };
  },
  computed: {
    title() {
      return `${this.pageData.length} Badges`;
    },
  },
  created() {
    this.getBadges();
  },
  methods: {
    sortPageData(direction, property) {
      this.sort = { property, direction };
      this.pageData = this.sortList(this.pageData, property, direction);
    },
    getBadges() {
      this.loading = true;
      badges
        .index()
        .then((response) => {
          this.pageData = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    search(query) {
      if (query === "") {
        this.getBadges();
        return;
      }
      this.loading = true;
      badges
        .search(query)
        .then((response) => {
          this.pageData = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setBadgeStyle(color) {
      return {
        border: color ? `5px solid ${color}` : "none",
      };
    },
  },
};
</script>

<style scoped lang="scss">
.badge {
  display: flex;
  align-items: center;

  &-image {
    height: 50px;
    width: 50px;
    min-height: 50px;
    min-width: 50px;
    border-radius: 100%;
    background: var(--eden-grey-tertiary);
    margin-right: 20px;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      border-radius: 100%;
      background: var(--eden-grey-tertiary);
    }
  }

  &-name {
    p {
      font-weight: 500;
      margin-bottom: 3px;
    }

    span {
      color: var(--eden-grey-tertiary);
    }
  }
}
</style>
