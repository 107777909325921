<template>
  <div>
    <el-dropdown @command="command">
      <slot name="actions-trigger">
        <span class="el-dropdown-link more">
          <i class="eden-icon-more"></i>
        </span>
      </slot>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item :icon="'eden-icon-edit'" :command="'form'"
          >Edit badge</el-dropdown-item
        >
        <el-dropdown-item
          :icon="'eden-icon-delete'"
          :command="'delete'"
          disabled
          >Delete badge</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
    <component
      :is="action.component"
      :action="'edit'"
      :show.sync="action.visibility"
      :badge="badge"
      @success="emitUpdate"
    />
  </div>
</template>

<script>
export default {
  name: "BadgesActions",
  props: {
    badge: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    BadgeDelete: () => import("./BadgeDelete"),
    BadgeForm: () => import("../BadgeForm"),
  },

  data() {
    return {
      action: {
        component: "",
        command: "",
        visibility: false,
      },
    };
  },
  computed: {
    componentVisibility() {
      return this.action.visibility;
    },
  },
  watch: {
    componentVisibility() {
      if (!this.action.visibility) {
        this.action.component = "";
      }
    },
  },
  methods: {
    command(command) {
      this.action.component = `badge-${command}`;
      this.action.command = command;
      setTimeout(() => {
        this.action.visibility = true;
      }, 150);
    },
    emitUpdate() {
      this.$emit(
        "update",
        this.action.command === "form" ? "edit" : this.action.command
      );
    },
  },
};
</script>
