import axios from "axios";

export default {
  index() {
    return axios.get("giftcards");
  },

  search(query) {
    return axios.get(`giftcards?search=${query}`);
  },

  filter(query) {
    return axios.get(`giftcards${query}`);
  },
  export(payload) {
    return axios.post(`giftcards/export`, payload);
  },
};
