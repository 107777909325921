<template>
  <div>
    <el-button
      type="plain"
      icon="eden-icon-upload"
      :disabled="!data.length"
      @click="showExport = true"
    >
      Export
    </el-button>
    <el-dialog
      title="Export"
      :visible.sync="showExport"
      @close="closeEvent"
      width="30%"
    >
      <el-form label-position="top" :model="form">
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item label="Select the data you'd like to export">
              <el-checkbox-group v-model="form.fields">
                <el-checkbox
                  v-for="(value, key, i) in fields"
                  :key="i"
                  :disabled="
                    key === 'recipient_name' ||
                    key === 'customer_name' ||
                    key === 'customer_email'
                  "
                  :label="key"
                >
                  {{ value }}
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="is-flex-wrap">
          <el-col :md="24">
            <el-form-item label="Status">
              <el-select
                v-model="form.status"
                placeholder="Select customer status"
              >
                <el-option
                  v-for="(status, i) in statuses"
                  :key="i"
                  :label="status.name"
                  :value="status.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item label="Select data format">
              <el-radio-group v-model="form.format">
                <el-radio
                  v-for="(key, value, i) in formats"
                  :key="i"
                  :label="value"
                >
                  {{ key }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="is-flex justify-end">
        <el-button
          type="primary"
          :loading="exporting"
          @click="exportAction"
          :disabled="!form.format || !form.fields.length || !form.status"
          >Export</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import giftCards from "@/requests/feature-tracker/gift-cards";
export default {
  name: "GardenRewardsExport",
  props: {
    search: {
      type: String,
      default: "",
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    filter: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      showExport: false,
      fields: {
        customer_name: "Customer",
        customer_email: "Customer email",
        recipient_name: "Recipient name",
        recipient: "Recipient email",
        status: "Status",
        value: "Amount",
        date: "Date",
        code: "Code",
      },
      statuses: [
        {
          name: "Redeemed",
          id: "redeemed",
        },
        {
          name: "Not Redeemed",
          id: "pending",
        },
      ],
      formats: {
        pdf: "PDF",
        csv: "CSV",
        xlsx: "Excel",
      },
      form: {
        fields: ["customer_email", "customer_name", "recipient_name"],
        format: "",
        status: "",
      },
      exportData: {},
      exporting: false,
    };
  },
  watch: {
    exporting() {
      if (!this.exporting) {
        this.closeEvent();
      }
    },
  },
  methods: {
    closeEvent() {
      this.form.fields = ["customer_email", "customer_name", "recipient_name"];
      this.form.format = "";
      this.exporting = false;
      this.showExport = false;
    },

    exportAction() {
      this.exporting = true;
      const payload = {
        export_format: this.form.format,
        export_headers: this.form.fields,
        status: this.form.status,
        start_date: this.filter?.start_date,
        end_date: this.filter?.end_date,
        search: this.search,
      };
      if (this.filter?.card_value) {
        payload.card_value_from = this.filter.card_value[0];
        payload.card_value_to = this.filter.card_value[1];
      }
      console.log(payload, "payload");
      giftCards
        .export(payload)
        .then((response) => {
          this.exporting = false;
          this.$message.success(response.data.message);
        })
        .catch((error) => {
          this.exporting = false;
          this.$message.error(error.response.data.message);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.downloadComboCsv,
.downloadComboXls {
  position: absolute;
  opacity: 0;
}
</style>
