<template>
  <eden-filter
    :width="270"
    :properties.sync="properties"
    :disabled="disabled"
    :loading="loading"
    @reset="reset"
    @filter="filter"
  />
</template>

<script>
export default {
  name: "GiftCardsFilter",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    clear: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      properties: {
        card_value: {
          key: "card_value",
          label: "Card Value",
          type: "number-range",
          options: [],
          value: [0, 0],
          searchable: false,
        },
        date: {
          key: ["start_date", "end_date"],
          label: "Date Created",
          type: "daterange",
          value: [],
        },
        status: {
          key: "status",
          label: "Redemption Status",
          type: "list",
          options: [
            {
              label: "Redeemed",
              value: "redeemed",
            },
            {
              label: "Not Redeemed",
              value: "pending",
            },
          ],
          value: [],
          searchable: false,
        },
      },
    };
  },
  computed: {
    gardeners() {
      return this.$store.getters.gardeners_list;
    },
  },
  watch: {
    clear() {
      if (this.clear) {
        this.reset();
      }
    },
  },
  methods: {
    reset() {
      Object.keys(this.properties).forEach((key) => {
        this.properties[key].value = [];
      });
    },
    filter() {
      let params = {
        params: {},
        paramsLabel: {},
      };

      Object.keys(this.properties).forEach((property) => {
        const { key, type, value } = this.properties[property];
        if (value && value.length) {
          switch (type) {
            case "list":
              params.params[key] = value[0];
              params.paramsLabel[key] = this.properties[property];
              break;
            case "list-multiple":
              params.params[key] = value;
              params.paramsLabel[key] = this.properties[property];
              break;
            case "number-range":
              if (value[0] !== value[1]) {
                params.params[key] = value;
                params.paramsLabel[key] = this.properties[property];
              }
              break;
            case "daterange":
              params.params[key[0]] = this.properties[property].value[0];
              params.params[key[1]] = this.properties[property].value[1];
              params.paramsLabel[key] = this.properties[property];
              break;
            default:
              break;
          }
        }
      });

      if (Object.keys(params.params).length) {
        this.$emit("filter", {
          params: params.params,
          paramsLabel: params.paramsLabel,
        });
      } else {
        this.$message.info("Filtering requires at least one property");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
