<template>
  <div>
    <eden-page-stats
      class="mt-0"
      :title="'Overview'"
      :loading="loading"
      :stats="stats"
      :show-periods="true"
      :periods="periods"
      :show-custom-period="true"
      @set-period="getGardenRewardsData"
    />
    <eden-table-actions :title="title" @search="search">
      <template slot="actions" v-if="allowAccessFor(['admin', 'growth'])">
        <gift-cards-filter
          :disabled="loading"
          :clear="filterParams.clear"
          @filter="filter"
          class="mr-1"
        />
        <GiftCardsExport
          :data="pageData"
          :search="searchQuery"
          :filter="filterParams.params"
        />
      </template>
    </eden-table-actions>
    <eden-loader v-if="loading" />
    <template v-else>
      <eden-filter-items
        v-if="filterParams.status"
        :params="filterParams.paramsLabel"
        @clear-filter="clearFilter"
        @clear-filters="clearFilters"
      />
      <template v-if="pageData.length">
        <el-table :data="pageData">
          <el-table-column width="200">
            <template #header>
              <eden-table-column-header
                :label="'Creator'"
                :property="'name'"
                :sort-property="sort.property"
                @sort="sortPageData($event, 'name')"
              />
            </template>
            <template v-slot="scope">
              <router-link
                :to="{
                  name: 'customers.individual',
                  params: { id: scope.row.user.id },
                }"
              >
                <span class="font-sm text-primary">
                  {{ formatName(scope.row.user.name) }}</span
                ></router-link
              >
              <span class="font-xsm"> {{ scope.row.user.email }} </span>
            </template>
          </el-table-column>
          <el-table-column>
            <template #header>
              <eden-table-column-header
                :label="'Gift Card Value'"
                :property="'amount'"
                :sort-property="sort.property"
                @sort="sortPageData($event, 'amount')"
              />
            </template>
            <template v-slot="scope">
              <span>{{ formatPrice(scope.row.amount) }}</span>
            </template>
          </el-table-column>
          <el-table-column width="200">
            <template #header>
              <eden-table-column-header
                :label="'Recipient'"
                :property="'recipient_name'"
                :sort-property="sort.property"
                @sort="sortPageData($event, 'recipient_name')"
              />
            </template>
            <template v-slot="scope">
              <span class="font-sm">
                {{ formatName(scope.row.recipient_name) }}</span
              >
              <span class="font-xsm"> {{ scope.row.recipient }} </span>
            </template>
          </el-table-column>
          <el-table-column width="150">
            <template #header>
              <eden-table-column-header
                :label="'Date Created'"
                :property="'created_at'"
                :sort-property="sort.property"
                @sort="sortPageData($event, 'created_at')"
              />
            </template>
            <template v-slot="scope">
              <span>{{ formatDate(scope.row.created_at, "mm d, y") }}</span>
            </template>
          </el-table-column>
          <el-table-column>
            <template #header>
              <eden-table-column-header
                :label="'Status'"
                :property="'customer_status'"
                :sort-property="sort.property"
                @sort="sortPageData($event, 'status')"
              />
            </template>
            <template v-slot="scope">
              <span class="font-sm table--status">
                <el-tag :type="setType(scope.row.status || 'redeemed')">
                  {{
                    formatText(
                      scope.row.status === "pending"
                        ? "Not-redeemed"
                        : "redeemed" || "redeemed",
                    )
                  }}
                </el-tag>
              </span>
            </template>
          </el-table-column>
        </el-table>
        <eden-pagination
          v-if="showPagination"
          :from="pagination.from"
          :to="pagination.to"
          :total="pagination.total"
          :current-page.sync="page"
        />
      </template>
      <eden-content-empty v-else :text="'No Gift Cards'" />
    </template>
  </div>
</template>
<script>
import GiftCardsFilter from "@/components/FeatureTracker/GiftCards/GiftCardsFilter.vue";
import giftCards from "@/requests/feature-tracker/gift-cards";
import GiftCardsExport from "../../components/FeatureTracker/GiftCardsExport.vue";
export default {
  name: "GiftCards",
  components: { GiftCardsFilter, GiftCardsExport },
  data() {
    return {
      loading: false,
      page: 1,
      pagination: { from: null, to: null, total: null },
      sort: { property: "name", direction: "asc" },
      pageData: [],
      showPagination: true,
      periods: { today: "Today", tomorrow: "Tomorrow", thisweek: "This week" },
      custom: { from: null, to: null },
      stats: [
        { label: "Total gift cards created", figure: 0 },
        { label: "Total gift card value", figure: 0 },
        { label: "Gift cards redeemed", figure: 0 },
        { label: "Gift cards not redeemed", figure: 0 },
      ],
      filterParams: {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      },
      searchQuery: "",
    };
  },
  computed: {
    discounts() {
      return this.$store.getters.discounts;
    },
    title() {
      const total = this.pagination.total;
      const result = this.pageData.length;
      const value = (this.showPagination ? total : result) || 0;
      return `${value} Gift cards`;
    },
  },
  watch: {
    page() {
      if(this.filterParams.status) {
        this.filter({
          params: this.filterParams.params,
          paramsLabel: this.filterParams.paramsLabel,
        });
      } else {
        this.getGardenRewardsData({
          period: this.period,
          from: this.custom.from,
          to: this.custom.to,
        });
      }
     
    },
  },
  created() {
    this.getGardenRewardsData({ period: "today" });
  },
  methods: {
    sortPageData(direction, property) {
      this.sort = { property, direction };
      this.pageData = this.sortList(this.pageData, property, direction);
    },
    getGardenRewardsData({ period, from, to }) {
      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });
      this.loading = true;
      const params = `?page=${this.page}&start_date=${start_date}&end_date=${end_date}`;
      giftCards
        .index(params)
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.stats[0].figure = data.summary.total_giftcards_created;
            this.stats[1].figure = this.formatPrice(
              data.summary.total_giftcards_created_value,
            );
            this.stats[2].figure = data.summary.total_giftcards_redeemed;
            this.stats[3].figure = data.summary.total_giftcards_not_redeemed;
            this.pageData = data.giftcards.data;
            this.pagination.from = data.giftcards.from;
            this.pagination.to = data.giftcards.to;
            this.pagination.total = data.giftcards.total;
            this.showPagination = true;
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search(query) {
      this.searchQuery = query;
      if (query === "") {
        this.getGardenRewardsData();
        return;
      }
      this.loading = true;
      giftCards
        .search(query)
        .then((response) => {
          const { status, data } = response.data;
          console.log(response, "search");
          if (status) {
            this.pageData = data.giftcards.data;
            console.log(this.pagination, "this.pagination")
            this.pagination.from = data.giftcards.from;
            this.pagination.to = data.giftcards.to;
            this.pagination.total = data.giftcards.total;
            this.showPagination = true;
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    filter({ params, paramsLabel }) {
      const sortedParams = this.sortObjectAsParams({...params, page: this.page});
      let stringedParams = sortedParams.replace(/\[\]/g, "");
      if (stringedParams.includes("card_value")) {
        let strArr = stringedParams.split("&");
        strArr = strArr.map((val, i) => {
          if (val.includes("card_value")) {
            if (i === 0) return val.replace("card_value", "card_value_from");
            else return val.replace("card_value", "card_value_to");
          } else return val;
        });
        stringedParams = strArr.join("&");
      }
      this.loading = true;
      giftCards
        .filter(`?${stringedParams}`)
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.filterParams = {
              status: true,
              params: params,
              paramsLabel: paramsLabel,
            };
            console.log(this.filterParams);
            this.pageData = data.giftcards.data;
            this.pagination.from = data.giftcards.from;
            this.pagination.to = data.giftcards.to;
            this.pagination.total = data.giftcards.total;
            this.showPagination = true;
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clearFilter(key) {
      delete this.filterParams.params[key];
      delete this.filterParams.paramsLabel[key];
      const keys = this.filterParams.params;
      if (Object.keys(keys).length) {
        this.filter({
          params: this.filterParams.params,
          paramsLabel: this.filterParams.paramsLabel,
        });
      } else {
        this.getGardenRewardsData({ period: "today" });
      }
    },
    clearFilters() {
      this.getGardenRewardsData({ period: "today" });
      this.filterParams = {
        clear: true,
        status: false,
        params: {},
        paramsLabel: {},
      };
    },
  },
};
</script>
<style lang="postcss" scoped>
.rewards {
  display: inline-block;
  margin: 5px 0;
  font-size: 0.775rem;
  color: var(--eden-green-primary);
  border-bottom: 1px solid var(--eden-green-primary);
}
</style>
