<template>
  <div>
    <el-drawer
      :title="title"
      :visible.sync="setShow"
      direction="rtl"
      @close="closeEvent"
    >
      <div class="el-drawer--content">
        <div class="el-drawer--content__body">
          <el-form :model="form" label-position="top" ref="form">
            <el-form-item prop="badge_image" :rules="validateField()">
              <el-row type="flex" class="w-100">
                <el-col :span="24">
                  <div class="badge_image">
                    <eden-image
                      v-loading="uploadingBgImage"
                      :image-url="form.badge_image"
                      :image-size="70"
                      :image-type="'circle'"
                      :borderStyles="`8px solid ${form.background_colour}`"
                      class="mb-1"
                    />
                    <eden-image-upload
                      :image-preview="false"
                      :image-url.sync="form.badge_image"
                      :image-folder="'discounts/badges'"
                      :image-text="'badge image'"
                      @uploading="uploadingBgImage = true"
                      @uploaded="uploadingBgImage = false"
                    />
                  </div>
                </el-col>
              </el-row>
            </el-form-item>

            <el-row type="flex">
              <el-col :span="24">
                <el-form-item label="Name" prop="name" :rules="validateField()">
                  <el-input type="text" v-model="form.name" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" class="mt-4">
              <el-col :span="24">
                <el-form-item
                  label="Description"
                  prop="badge_description"
                  :rules="validateField()"
                >
                  <el-input
                    type="textarea"
                    rows="4"
                    v-model="form.badge_description"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <div class="el-drawer--footer">
        <el-button
          type="primary"
          :loading="adding"
          @click="save"
          :disabled="disableButton"
        >
          {{ action === "edit" ? "Update" : "Add" }} Badge</el-button
        >
      </div>
    </el-drawer>
  </div>
</template>

<script>
import badges from "@/requests/discounts/badges";

export default {
  name: "BadgeForm",
  props: {
    action: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: false,
    },
    badge: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        badge_image: "",
        background_colour: "",
        name: "",
        badge_description: "",
        validity_start_date: null,
        validity_end_date: null,
        has_validity_period: false,
      },
      uploadingBgImage: false,
      showColorPicker: false,
      randomColour: "",
      bgColours: [],
      adding: false,
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    title() {
      return this.action === "add" ? "Add Badge" : "Edit Badge";
    },
    disableButton() {
      return this.form.name === "" || this.form.badge_image === "";
    },
  },
  watch: {
    show() {
      if (this.show && this.action === "add") {
        const hex = Math.floor(Math.random() * 16777215).toString(16);

        const color = `#${hex}`;
        this.form.background_colour = color;
        this.bgColours.push(color);
      }

      if (this.show && this.action === "edit") {
        const badge = this.badge;
        this.form.badge_image = badge.badge_image_url;
        this.form.background_colour = badge.background_colour;
        this.form.name = badge.name;
        this.form.badge_description = badge.description;
        this.form.has_validity_period = badge.has_validity_period;
        this.form.validity_start_date = badge.validity_start_date
          ? this.formatDate(badge.validity_start_date, "y-m-d")
          : null;
        this.form.validity_end_date = badge.expiry_date
          ? this.formatDate(badge.expiry_date, "y-m-d")
          : null;
        if (this.badge.background_colour) {
          this.bgColours.push(this.badge.background_colour);
        }
        if (this.form.background_colour.includes("rgba")) {
          this.formatColors();
        }
      }
    },
    expiryDate() {
      if (!this.form.has_validity_period) {
        this.form.validity_start_date = null;
        this.form.validity_end_date = null;
      }
    },
  },
  methods: {
    closeEvent() {
      this.setShow = false;
      this.form.background_colour = "";
      this.form.badge_image = "";
      this.form.name = "";
      this.form.badge_description = "";
      this.form.has_validity_period = false;
      this.form.validity_start_date = null;
      this.form.validity_end_date = null;
      this.bgColours = [];
    },
    disabledDates(time) {
      return time.getTime() <= new Date().getTime();
    },
    disabledStartDate(time) {
      return time.getTime() <= new Date(this.form.start_date).getTime();
    },

    changeAvatarColor(color) {
      let colorExists = this.bgColours.find((item) => item === color);
      if (!colorExists) {
        this.bgColours.push(color);
      }
      this.form.background_colour = color;
    },

    save() {
      this.action === "edit" ? this.update() : this.add();
    },
    formatColors() {
      const components = this.form.background_colour.match(/\d+/g);

      const r = components[0];
      const g = components[1];
      const b = components[2];
      // const a = components[3];

      // this.form.background_colour.R = r;
      // this.form.background_colour.G = g;
      // this.form.background_colour.B = b;
      // this.form.background_colour.A = a;

      const toHex = (value) => {
        const hex = parseInt(value).toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      };

      this.form.background_colour = "#" + toHex(r) + toHex(g) + toHex(b);
    },
    add() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.adding = true;
        if (!this.form.has_validity_period) {
          delete this.form.validity_start_date;
          delete this.form.validity_end_date;
        }
        const payload = this.form;
        badges
          .add(payload)
          .then((response) => {
            if (response.data.status) {
              this.success(response);
            } else {
              this.$message.error(response.data.message);
              this.adding = false;
            }
          })
          .catch((error) => {
            this.adding = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
    update() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.adding = true;
        if (!this.form.has_validity_period) {
          delete this.form.validity_start_date;
          delete this.form.validity_end_date;
        }
        const payload = this.form;
        delete payload.index;
        console.log(payload);
        badges
          .update(this.badge.id, payload)
          .then((response) => {
            if (response.data.status) {
              this.success(response);
            } else {
              this.$message.error(response.data.message);
              this.adding = false;
            }
          })
          .catch((error) => {
            this.adding = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },

    success(message) {
      this.$message.success(message.data.message);
      this.adding = false;
      this.$emit("success");
      this.closeEvent();
    },
  },
};
</script>

<style lang="scss" scoped>
.badge_image {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.badge__colors {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;

  &-btn {
    height: 36px;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    cursor: pointer;

    &:not(:first-child) {
      margin-left: 12px;
    }

    i {
      font-weight: 600;
      color: #0f241b;
    }
  }

  &-btn.add {
    background: transparent !important;
    border: 2px dashed #8aa398;
    position: relative;

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .el-color-picker {
      opacity: 0;
    }
  }
}
</style>
