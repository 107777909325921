<template>
  <div>
    <eden-page-header :title="'Garden Feature Tracking Centre'" />
    <template>
      <div>
        <el-tabs v-model="tab" @tab-click="updateRouteQuery">
          <el-tab-pane label="Referrals" name="referrals-and-rewards">
            <garden-rewards-centre-data />
          </el-tab-pane>
          <el-tab-pane
            label="Retention"
            name="retention-centre-data"
          >
            <retention-centre-data />
          </el-tab-pane>
          <el-tab-pane v-if="enableTab" label="Badges" name="badges">
            <badges />
          </el-tab-pane>
          <el-tab-pane label="Gift Cards" name="gift-cards">
            <gift-cards />
          </el-tab-pane>
        </el-tabs>
      </div>
    </template>
  </div>
</template>

<script>
import GardenRewardsCentreData from "@/components/DiscountsAndRewards/GardenRewards/GardenRewardsCentreData";
// import Discounts from "@/components/DiscountsAndRewards/Discounts/Discounts";
import RetentionCentreData from "@/components/DiscountsAndRewards/Retention/RetentionCentreData";
import Badges from "@/components/DiscountsAndRewards/Badges/Badges";
import GiftCards from "@/pages/feature-tracker/gift-cards.vue";

export default {
  name: "DiscountsAndRewards",
  components: {
    GardenRewardsCentreData,
    RetentionCentreData,
    // Discounts,
    // Rewards,
    Badges,
    GiftCards,
  },
  data() {
    return {
      tab: "discounts",
    };
  },
  computed: {
    enableTab() {
      return true;
    },
  },
  watch: {
    $route() {
      this.updateRouteQuery();
    },
  },
  created() {
    let { t } = this.$route.query;
    this.tab = t ? t : "referrals-and-rewards";
  },
  methods: {
    updateRouteQuery(tab) {
      const { t } = this.$route.query;
      if (t === this.tab) {
        return false;
      }
      this.$router.push({
        name: "feature-tracker.index",
        query: { t: tab.name },
      });
    },
  },
};
</script>
